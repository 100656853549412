import { ColorScale, HorizontalBarChartStacked } from "@eds/vanilla";
import { useEffect } from "react";
import CustomGraphActions from "../../../components/CustomGraphActions/CustomGraphActions";
import Loading from "../../../components/Loading/Loading";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";
import _ from "lodash";

export default function AuditCountBySupplier({ audit_count_by_supplier, getAnalyticsGraphsToggle, getSiteWalkDataByAnalyticsToggle, loadingVal, errMsg }) {
  const getChartData = () => {
    const regions = audit_count_by_supplier.reduce(
      (acc, val) => (acc.includes(val.Location) ? acc : [...acc, val.Location]),
      []
    );
    const supplierNames = audit_count_by_supplier.reduce(
      (acc, val) =>
        acc.includes(val["Supplier Name"])
          ? acc
          : [...acc, val["Supplier Name"]],
      []
    );
    const data = {
      common: supplierNames,
      series: regions.map((region) => {
        const count = [];
        supplierNames.forEach((name) => {
          const obj = audit_count_by_supplier.find(
            (val) => val.Location === region && val["Supplier Name"] === name
          );
          count.push(obj?.Count || 0);
        });
        return {
          name: region,
          values: count,
        };
      }),
    };
    return data;
  };

  useEffect(() => {
    if (!_.isEmpty(audit_count_by_supplier)) {
      const data = getChartData();
      const chartHeight = data?.common?.length * 40;
      const mycolorScale = new ColorScale({ reverse: true });
      const chart = new HorizontalBarChartStacked({
        element: document.getElementById("auditCountbySupplierGraph"),
        data: data,
        x: { unit: "Count" },
        height: chartHeight,
        colorScale: mycolorScale,
      });
      mycolorScale.init();
      chart.init();
      return () => {
        chart.destroy();
        let element = document.getElementById("auditCountbySupplierGraph");
        while (element?.firstChild) {
          element.removeChild(element.firstChild);
        }
      };
    }
  }, [audit_count_by_supplier]);

  return (
    <>
      <li
        tabIndex={7}
        className={`tile mb-sm accordion-item ${getAnalyticsGraphsToggle.audit_count_by_supplier
          ? "opened"
          : ""
          }`}
      >
        <div
          className="title stickyTileHeader"
          onClick={() => {
            getSiteWalkDataByAnalyticsToggle(
              "audit_count_by_supplier"
            );
          }}
        >
          <div className="header p-sm">
            <div className="left">Audit Count by Supplier</div>
          </div>
        </div>
        <div className="content">
          {loadingVal?.audit_count_by_supplier ? (
            <Loading />
          ) : (
            <>
              {errMsg?.audit_count_by_supplier !== "Success" ? (
                <AlertEDS
                  errMsg={errMsg?.audit_count_by_supplier}
                  status_code={201}
                  close={false}
                />
              ) : (
                <>
                  <CustomGraphActions
                    nodeId={"auditCountbySupplierGraph"}
                    fileName={"Audit_CountBySupplier"}
                    data={audit_count_by_supplier}
                  />
                  <div id="auditCountbySupplierGraph"></div>
                </>
              )}
            </>
          )}
        </div>
      </li>


    </>
  );
}
