import React, { useEffect } from "react";
import { ColorScale, HorizontalBarChart } from "@eds/vanilla";
import CustomGraphActions from "../../../components/CustomGraphActions/CustomGraphActions";
import Loading from "../../../components/Loading/Loading";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";
import _ from "lodash";

export default function SiteWalkGraph({ site_walk_region, regionColumnName, getAnalyticsGraphsToggle, getSiteWalkDataByAnalyticsToggle, loadingVal, errMsg }) {
  useEffect(() => {
    if(!_.isEmpty(site_walk_region)){
    const data = {
      common: site_walk_region?.map((obj) => obj?.location),
      series: [
        {
          name: `Count by ${regionColumnName}`,
          values: site_walk_region?.map((obj) => obj?.Count),
        },
      ],
    };
    const chartHeight = data?.common?.length * 40;
    const mycolorScale = new ColorScale({ reverse: true });
    const chart = new HorizontalBarChart({
      element: document.getElementById(
        "totalSiteWalkCompletionCountByLocation"
      ),
      data: data,
      x: { unit: "Count" },
      height: chartHeight,
      colorScale: mycolorScale,
      legend: { isHidden: true },
    });
    mycolorScale.init();
    chart.init();
    return () => {
      chart.destroy();
      let element = document.getElementById(
        "totalSiteWalkCompletionCountByLocation"
      );
      while (element?.firstChild) {
        element.removeChild(element.firstChild);
      }
    };
  }
  }, [site_walk_region, regionColumnName]);

  return (
     <li
        tabIndex={0}
        className={`tile mb-sm accordion-item ${getAnalyticsGraphsToggle.completion_count_by_location ? "opened" : ""
          }`}
      >
        <div
          className="title stickyTileHeader"
          onClick={() => {
            getSiteWalkDataByAnalyticsToggle("completion_count_by_location");
          }}
        >
          <div className="header p-sm">
            <div className="left">
              Total Site Walk Completion Count – By Location
            </div>
          </div>
        </div>
        <div className="content">
          {loadingVal.completion_count_by_location ? (
            <Loading />
          ) : (
            <>
              {errMsg.completion_count_by_location !== "Success" ? (
                <AlertEDS
                  errMsg={errMsg.completion_count_by_location}
                  status_code={201}
                  close={false}
                />
              ) : (
                <> <CustomGraphActions
                  nodeId={"totalSiteWalkCompletionCountByLocation"}
                  fileName={"Total_SiteWalk_Completion_CountByLocation"}
                  data={site_walk_region}
                />
                  <div id="totalSiteWalkCompletionCountByLocation"></div>
                </>
              )}
            </>
          )}
        </div>
      </li>
  );
}
