import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import _ from "lodash";
import { getReportsFiltersData } from "../../api/EHSReports.api";
import {
  getSiteWalkDataAPi,
  getSiteWalkData2APi,
  getSiteWalkResponsesData,
  getSiteWalkAuditCountBySupplierData,
  getCommentsNoComments
} from "../../api/SiteWalk.api";
import Loading from "../../components/Loading/Loading";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import SiteWalkGraph from "./Graphs/SiteWalkGraph";
import SiteWalkGraph2 from "./Graphs/SiteWalkGraph2";
import CustomDatePickerForm from "../../components/CustomDatePickerForm/CustomDatePickerForm";
import SafeResponsesUnsafeResponsesGraph from "./Graphs/SafeResponsesUnsafeResponsesGraph";
import UnsafeResponsePieChart from "./Graphs/UnsafeResponsePieChart";
import AuditCountBySupplier from "./Graphs/AuditCountBySupplier";
import { AuditsByAuditType } from "./Graphs/AuditsByAuditType";
import "./SiteWalk.css";
import SafetyActionsGraph from "./Graphs/SafetyActionsGraph";
import { AuditsByCustomer } from "./Graphs/AuditsByCustomer";
import { AuditsByBusiness } from "./Graphs/AuditsByBusiness";
import { CommentsNoCommentsGraph } from "./Graphs/CommentsNoCommentsGraph";

export default function SiteWalk() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loadingVal, setLoading] = useState({
    completion_count_by_location: false,
    completion_count_by_auditor: false,
    safety_actions: false,
    safe_unsafe_responses: false,
    audit_count_by_supplier: false,
    comment_stats: false
  });
  const [formIdData, setFormIdData] = useState([]);
  const [errMsg, setErrMsg] = useState({
    completion_count_by_location: "",
    completion_count_by_auditor: "",
    safety_actions: "",
    safe_unsafe_responses: "",
    audit_count_by_supplier: "",
    comment_stats: ""
  });
  const [getSiteWalkData, setSiteWalkData] = useState({});
  const [getSiteWalkData2, setSiteWalkData2] = useState({});
  const [getResponsesData, setResponsesData] = useState({});
  const [getAuditCountBySupplierData, setAuditCountBySupplierData] = useState(
    {}
  );
  // const [getAuditCountByAuditTypeData, setAuditCountByAuditTypeData] = useState(
  //   {}
  // );
  const [regionColumnName, setRegionColumnName] = useState("Region");
  const [currentDay, setCurrentDay] = useState(
    moment().local().format("YYYY-MM-DD")
  );
  const [isAutoCall, setIsAutoCall] = useState(false);
  // const { getUserAccess } = useContext(UserContext);
  // const [sitewalkAccess, setSitewalkAccess] = useState([]);
  // const [filtersAccess, setFiltersAccess] = useState([]);
  const [filterData, setfiltersData] = useState({});
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [filtersErrMsg, setFiltersErrMsg] = useState("");
  const [getUsersOption, setUsersOption] = useState([]);
  const [getMarketDetails, setMarketDetails] = useState([]);
  const [getCustomerDetails, setCustomerDetails] = useState([]);
  const [getRegionDetails, setRegionDetails] = useState([]);
  const [getSupplierDetails, setSupplierDetails] = useState([]);
  const [getStateDetails, setStateDetails] = useState([]);
  const [validationMsg, setValidationMsg] = useState("");
  const [getAnalyticsGraphsToggle, setAnalyticsGraphsToggle] = useState({
    completion_count_by_location: false,
    completion_count_by_auditor: false,
    safety_actions: false,
    audit_count_by_bu: false,
    safe_unsafe_responses: false,
    audit_count_by_audit: false,
    audit_count_by_supplier: false,
    audit_count_by_customer: false,
    comment_stats: false,
  });
  const [selectedBusinessUnit, setSelecetedBusinessUnit] = useState([]);
  const { employeeId } = useSelector((state) => state.loggedInUser.user);
  const { headers, headersList } = useSelector((state) => state.envVariables);
  const features = useSelector((state) => state.userAccess.features);
  const forms = useSelector((state) => state.userAccess.forms);
  const usergroupId = useSelector(
    (state) => state.loggedInUser.user.usergroupId
  );
  const [getCommentsNoCommentsData, setCommentsNoCommentsData] = useState([]);
  const [getAvailableGraph, setAvailableGraph] = useState({
    completion_count_by_location: false,
    completion_count_by_auditor: false,
    safety_actions: false,
    safe_unsafe_responses: false,
    audit_count_by_supplier: false,
    audit_count_by_audit: false,
    audit_count_by_bu: false,
    audit_count_by_customer: false,
    comment_stats: false
  });
  const [getAvailableGraphVal, setAvailableGraphVal] = useState([])

  useEffect(() => {
    let locationName = features?.find((val) => val.name === "LOCATION").value;
    if (_.isEmpty(locationName)) {
      locationName = "Region";
    }
    setRegionColumnName(locationName);
  }, []);

  let datepickerData = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    formIdData,
    setFormIdData,
    getUsersOption,
    setUsersOption,
    getMarketDetails,
    setMarketDetails,
    getCustomerDetails,
    setCustomerDetails,
    getRegionDetails,
    setRegionDetails,
    getSupplierDetails,
    setSupplierDetails,
    getStateDetails,
    setStateDetails,
    selectedBusinessUnit,
    setSelecetedBusinessUnit,
  };

  const validateFilterValues = (property) => {
    let errorMsg = "";
    if (_.isNull(startDate)) {
      errorMsg = "Please select Start Date";
    } else if (_.isNull(endDate)) {
      errorMsg = "Please select End Date";
    } else if (_.isEmpty(getRegionDetails)) {
      errorMsg = "Please select at least one " + regionColumnName;
    } else if (_.isEmpty(getStateDetails)) {
      errorMsg = "Please select at least one State";
    } else if (_.isEmpty(getUsersOption)) {
      errorMsg = "Please select at least one Auditor";
    } else if (
      _.isEmpty(getMarketDetails) &&
      features?.some((val) => val.name === "MARKET" && val.value)
    ) {
      errorMsg = "Please select at least one Market";
    } else if (
      _.isEmpty(getCustomerDetails) &&
      features?.some((val) => val.name === "CUSTOMER" && val.value)
    ) {
      errorMsg = "Please select at least one Customer";
    } else if (_.isEmpty(getSupplierDetails)) {
      errorMsg = "Please select at least one Supplier";
    } else if (_.isEmpty(formIdData)) {
      errorMsg = "Please select at least one Form";
    } else if (_.isEmpty(selectedBusinessUnit)) {
      errorMsg = "Please select at least one Bussiness Unit";
    } else {
      errorMsg = "";
    }
    setErrMsg((val) =>
      Object.keys(val).reduce((acc, key) => {
        if (key === property) {
          acc[key] = errorMsg;
        } else {
          acc[key] = val[key];
        }
        return acc;
      }, {})
    );
    setValidationMsg(errorMsg);
    return errorMsg.length < 1;
  };

  let param = {
    start_date: moment(startDate).format("YYYY-MM-DD"),
    end_date: moment(endDate).format("YYYY-MM-DD"),
    formId: formIdData,
    response_type: "2",
    status: "*",
    region: getRegionDetails,
    markets: getMarketDetails,
    customers: getCustomerDetails,
    suppliers: getSupplierDetails,
    reporter: getUsersOption,
    state: getStateDetails,
    timeZone: moment.tz.guess(true),
    business_unit: selectedBusinessUnit,
  };

  const getSiteWalkDataByAnalyticsToggle = (graphName, isAccordion = true) => {
    const isValidParam = validateFilterValues(graphName);
    if (graphName === "completion_count_by_location") {
      isValidParam &&
        (!getAnalyticsGraphsToggle.completion_count_by_location || !isAccordion) &&
        getSiteWalkDataAPi(
          param,
          setLoading,
          setErrMsg,
          setSiteWalkData,
          headersList
        );
      isAccordion &&
        setAnalyticsGraphsToggle((val) => ({
          ...val,
          completion_count_by_location: !val.completion_count_by_location,
        }));
    } else if (graphName === "completion_count_by_auditor") {
      isValidParam &&
        (!getAnalyticsGraphsToggle.completion_count_by_auditor || !isAccordion) &&
        getSiteWalkData2APi(
          param,
          setLoading,
          setErrMsg,
          setSiteWalkData2,
          headersList
        );
      isAccordion &&
        setAnalyticsGraphsToggle((val) => ({
          ...val,
          completion_count_by_auditor: !val.completion_count_by_auditor,
        }));
    } else if (graphName === "safe_unsafe_responses") {
      isValidParam &&
        (!getAnalyticsGraphsToggle.safe_unsafe_responses || !isAccordion) &&
        getSiteWalkResponsesData(
          param,
          setLoading,
          setErrMsg,
          setResponsesData,
          headersList
        );
      isAccordion &&
        setAnalyticsGraphsToggle((val) => ({
          ...val,
          safe_unsafe_responses: !val.safe_unsafe_responses,
        }));
    }
    else if (graphName === "audit_count_by_supplier") {
      isValidParam &&
        (!getAnalyticsGraphsToggle.audit_count_by_supplier || !isAccordion) &&
        getSiteWalkAuditCountBySupplierData(
          param,
          setLoading,
          setErrMsg,
          setAuditCountBySupplierData,
          headersList
        );
      isAccordion &&
        setAnalyticsGraphsToggle((val) => ({
          ...val,
          audit_count_by_supplier: !val.audit_count_by_supplier,
        }));
    }
    else if (graphName === "comment_stats") {
      isValidParam &&
        (!getAnalyticsGraphsToggle.comment_stats || !isAccordion) &&
        getCommentsNoComments(
          param,
          setLoading,
          setErrMsg,
          setCommentsNoCommentsData,
          headersList
        );
      isAccordion &&
        setAnalyticsGraphsToggle((val) => ({
          ...val,
          comment_stats: !val.comment_stats,
        }));
    }
    else {
    }
  };

  const getDisableApplyToggle = useMemo(() => {
    return Object.values(loadingVal).includes(true) || filtersLoading;
  }, [loadingVal, filtersLoading]);

  useEffect(() => {
    if (startDate && endDate) {
      const param = {
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        timeZone: moment.tz.guess(true),
      };
      getReportsFiltersData(
        param,
        setfiltersData,
        setFiltersLoading,
        setFiltersErrMsg,
        headers
      );
    }
  }, [startDate, endDate, headers]);

  useEffect(() => {
    setCustomerDetails(filterData?.customers || []);
    setMarketDetails(filterData?.markets || []);
    setSupplierDetails(filterData?.suppliers || []);
    const defaultReporter = Object.entries(
      filterData?.reporters?.find(
        (val) => Object.entries(val)?.[0]?.[0] === employeeId
      ) || []
    )?.[0]?.[0];
    setUsersOption(_.isEmpty(defaultReporter) ? [] : [defaultReporter]);
    setRegionDetails(filterData?.locations || []);
    setSelecetedBusinessUnit(filterData?.business_unit || []);
    setStateDetails(filterData?.state || []);
    const defaultForm = filterData?.form?.[0]?.formId;
    setFormIdData(
      defaultForm || typeof defaultForm !== "undefined" ? [defaultForm] : []
    );
    if (!_.isEmpty(filterData)) {
      setIsAutoCall(true);
    }

    const updateStickyHeaderTopValue = () => {
      const _headerList = document.querySelectorAll(".stickyTileHeader");
      const _filtersHeight =
        document.querySelector(".filterDiv")?.offsetHeight || 0;
      if (_headerList?.length) {
        _headerList.forEach((header) => {
          header.style.top = _filtersHeight + "px";
        });
      }
    };

    window.addEventListener("resize", updateStickyHeaderTopValue);
    window.dispatchEvent(new Event("resize"));

    return () => {
      window.removeEventListener("resize", updateStickyHeaderTopValue);
    };

  }, [filterData, employeeId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newDay = moment().local().format("YYYY-MM-DD");
      if (currentDay !== newDay) {
        setCurrentDay(newDay);
        setStartDate(
          new Date(
            moment(currentDay, "YYYY-MM-DD").format("YYYY-MM-DD 00:00:00")
          )
        );
        setEndDate(
          new Date(moment(newDay, "YYYY-MM-DD").format("YYYY-MM-DD 23:59:59"))
        );
        setIsAutoCall(true);
      }
    }, 60000);
    return () => clearInterval(intervalId);

  }, [currentDay]);

  useEffect(() => {
    if (isAutoCall && !_.isEmpty(getAvailableGraphVal)) {
      const defaultToggle =
        Object.values(getAnalyticsGraphsToggle).indexOf(true) < 0;
      if (defaultToggle) {
        getSiteWalkDataByAnalyticsToggle(getAvailableGraphVal[0]);
      } else {
        for (let i in getAnalyticsGraphsToggle) {
          if (getAnalyticsGraphsToggle[i]) {
            getSiteWalkDataByAnalyticsToggle(i, false);
          }
        }
      }
      setIsAutoCall(false);
    }
  }, [isAutoCall, getAvailableGraphVal]);



  useEffect(() => {
    if (!_.isEmpty(formIdData) && !_.isEmpty(forms)) {
      let _availableGraph;
      forms?.audit_forms?.forEach(i => {
        if (i.formid === formIdData[0]) {
          setAvailableGraphVal(i.graphs);
          _availableGraph = i.graphs;
        }
      });

      let _getAvailableGraph = _.cloneDeep(getAvailableGraph);

      Object.keys(_getAvailableGraph).filter(i => {
        if (_availableGraph.includes(i)) {
          _getAvailableGraph[i] = true
        } else {
          _getAvailableGraph[i] = false
        }
      });

      setAvailableGraph(_getAvailableGraph);
    }

  }, [formIdData, forms]);

  return (
    <div className="row">
      <div className="tile filterDiv">
        <CustomDatePickerForm
          formName="site-walk"
          datepickerData={datepickerData}
          filterData={filterData}
          // submitData={() => {
          //   setLoading({ completion_count_by_location: true, completion_count_by_auditor: true });
          // }}
          callApply={() => {
            setIsAutoCall(true);
          }}
          disableButtton={getDisableApplyToggle}
        // filtersAccess={filtersAccess}
        />
      </div>
      {filtersLoading ? (
        <Loading />
      ) : (
        <>
          {filtersErrMsg !== "Success" ? (
            <div className="tile sm-12 md-12 lg-12 xl-12">
              <AlertEDS
                errMsg={filtersErrMsg}
                status_code={201}
                close={false}
              />
            </div>
          ) : (
            <div className="accordion column sm-12 md-12 lg-12 xl-12">
              <ul className="accordion-list">
                {getAvailableGraph.completion_count_by_location && (
                  <SiteWalkGraph
                    site_walk_region={getSiteWalkData}
                    regionColumnName={regionColumnName}
                    getAnalyticsGraphsToggle={getAnalyticsGraphsToggle}
                    getSiteWalkDataByAnalyticsToggle={getSiteWalkDataByAnalyticsToggle}
                    loadingVal={loadingVal}
                    errMsg={errMsg}
                  />
                )}
                {getAvailableGraph.completion_count_by_auditor && (
                  <SiteWalkGraph2
                    site_walk_name={getSiteWalkData2}
                    getAnalyticsGraphsToggle={getAnalyticsGraphsToggle}
                    getSiteWalkDataByAnalyticsToggle={getSiteWalkDataByAnalyticsToggle}
                    loadingVal={loadingVal}
                    errMsg={errMsg}
                  />
                )}
                {getAvailableGraph.safety_actions && (
                  <SafetyActionsGraph
                    param={param}
                    isAutoCall={isAutoCall}
                    getAnalyticsGraphsToggle={getAnalyticsGraphsToggle}
                    setAnalyticsGraphsToggle={setAnalyticsGraphsToggle}
                    validateFilterValues={validateFilterValues}
                    validationMsg={validationMsg}
                  />
                )}
                {getAvailableGraph.safe_unsafe_responses && (
                  <SafeResponsesUnsafeResponsesGraph
                    safeUnsafeResponsesData={getResponsesData}
                    getAnalyticsGraphsToggle={getAnalyticsGraphsToggle}
                    getSiteWalkDataByAnalyticsToggle={getSiteWalkDataByAnalyticsToggle}
                    loadingVal={loadingVal}
                    errMsg={errMsg}
                  />
                )}
                {/* <AuditCountByWorkerTypeGraph
                    param={param}
                    isAutoCall={isAutoCall}
                    getAnalyticsGraphsToggle={getAnalyticsGraphsToggle}
                    setAnalyticsGraphsToggle={setAnalyticsGraphsToggle}
                    validationMsg={validationMsg}
                    validateFilterValues={validateFilterValues}
                  /> */}
                {!["27", "29"].includes(usergroupId) && getAvailableGraph.audit_count_by_supplier && (
                  <AuditCountBySupplier
                    audit_count_by_supplier={getAuditCountBySupplierData}
                    getAnalyticsGraphsToggle={getAnalyticsGraphsToggle}
                    getSiteWalkDataByAnalyticsToggle={getSiteWalkDataByAnalyticsToggle}
                    loadingVal={loadingVal}
                    errMsg={errMsg}
                  />
                )}
                {getAvailableGraph.audit_count_by_audit && (
                  <AuditsByAuditType
                    param={param}
                    isAutoCall={isAutoCall}
                    getAnalyticsGraphsToggle={getAnalyticsGraphsToggle}
                    setAnalyticsGraphsToggle={setAnalyticsGraphsToggle}
                    validateFilterValues={validateFilterValues}
                    validationMsg={validationMsg}
                  />
                )}
                {getAvailableGraph.audit_count_by_bu && (
                  <AuditsByBusiness
                    param={param}
                    isAutoCall={isAutoCall}
                    getAnalyticsGraphsToggle={getAnalyticsGraphsToggle}
                    setAnalyticsGraphsToggle={setAnalyticsGraphsToggle}
                    validateFilterValues={validateFilterValues}
                    validationMsg={validationMsg}
                  />
                )}

                {features.some((val) => val.name === "CUSTOMER" && val.value) && getAvailableGraph.audit_count_by_customer && (
                  <AuditsByCustomer
                    param={param}
                    isAutoCall={isAutoCall}
                    getAnalyticsGraphsToggle={getAnalyticsGraphsToggle}
                    setAnalyticsGraphsToggle={setAnalyticsGraphsToggle}
                    validateFilterValues={validateFilterValues}
                    validationMsg={validationMsg}
                  />
                )}
                {getAvailableGraph.comment_stats && (
                  <CommentsNoCommentsGraph
                    CommentsNoCommentsData={getCommentsNoCommentsData}
                    getAnalyticsGraphsToggle={getAnalyticsGraphsToggle}
                    getSiteWalkDataByAnalyticsToggle={getSiteWalkDataByAnalyticsToggle}
                    loadingVal={loadingVal}
                    errMsg={errMsg}
                  />
                )}
              </ul>
            </div>
          )}
        </>
      )}
    </div>
  );
}
